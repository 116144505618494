import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const image = getImage(post.frontmatter.thumbnail);
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-infoPage bg-no-repeat bg-center bg-cover  z-20 ">
          <Link to="/info">
          <h2 className="font-Copperplate text-center text-2xl sm:text-2xl py-10">Information</h2>
          </Link>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[900px] mx-auto pt5 pb-10">
            <article className="blog mb-20">
              <header>
                <h1 className="font-medium text-xl sm:text-2xl border-b-2 border-key-sand mt-10 mb-5 py-2">
                  {post.frontmatter.title}
                </h1>
                <p className="my-3">
                  <div className="inline-block">
                    <svg class="inline h-5 w-5 mb-1"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
                    <span className="ml-1">{post.frontmatter.date}</span>
                  </div>
                  <div className="inline-block ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-folder inline-block" viewBox="0 0 16 16">
                      <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                    </svg>
                    <span className="ml-1 ">{post.frontmatter.category}</span>
                  </div>
                </p>
                <div className="w-full">
                {
                  post.frontmatter.thumbnail ?
                    <GatsbyImage
                      image={image} 
                      alt={`${post.frontmatter.title}`}
                      className="mb-10"
                      imageStyle={{objectFit: `contain`}}
                    />
                  :""
                }
                </div>
              </header>

              <MDXRenderer frontmatter={post.frontmatter} itemProp="articleBody">{post.body}</MDXRenderer>

            </article>
            <nav className="blog-post-nav mb-10">
              <Link to="/info" class="w-44 mt-10 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
                ← お知らせ一覧へ
              </Link>
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
query BlogPostBySlug(
  $id: String!,
  $previousPostId: String,
  $nextPostId: String,
){
  site {
    siteMetadata {
      title
      siteUrl
    }
  }
  mdx(id: {eq: $id}) {
    id
    excerpt(truncate: true, pruneLength: 160)
    body
    frontmatter {
      title
      date(formatString: "YYYY/MM/DD")
      description
      slug
      tags
      category
      thumbnail {
        childImageSharp {
          gatsbyImageData(
            width: 900
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
          original{
            src
          }
        }
      }
      
    }
  }
  previous: mdx(id: {eq: $previousPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
  next: mdx(id: {eq: $nextPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
}
`
